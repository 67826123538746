footer{
    background-color: #28c6d4;
    /* background-image: url("https://www.transparenttextures.com/patterns/brick-wall.png"); */
    justify-content: center;
    height: 120px;
    margin-top: 100px;
    margin-bottom: 0px;
    /* margin: 0px 3px; */
    align-items: center;
}

.Footerdiv{
    text-align: center;
    padding: 5px 5px;
    margin-top: 30px;
}

.Footerdiv a{
    padding: 4px;
    text-decoration: none;
    color: black;
}

/* .footerSpan{
    padding: 2px 3px;
    margin-left: 35%;
} */

.footerSpan {
    display: flex;
    justify-content: center;
  }

@media (max-width:1000px){
    .footerSpan{
        /* border: 2px solid red;
        justify-content: center;
        text-align: center;
        padding: 2px 3px; */
        /* margin-left: 50px; */
        /* margin-right: 50px; */
    }
}