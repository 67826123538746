    
.Clcard {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    width: 250px;
    margin: 20px 20px;
    /* border: 2px solid red; */
    height: 100px;
}

.Clproduct--image{
    width: 100%;
    height: 96px;
    object-fit: contain;
    /* border: 2px solid rgb(0, 85, 255); */
    
}
