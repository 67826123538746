.btn {
    margin-top: 10px;
    position: relative;
    font-size: 17px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1em 2.5em;
    display: inline-block;
    border-radius: 6em;
    transition: all .2s;
    border: none;
    font-family: inherit;
    font-weight: 500;
    color: white !important;
    background-color: #28c6d4 !important;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
   
.btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
   
.btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
}
   
.btn::after {
    background-color: #36aabe;
}
   
.btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}

form {
    max-width: 500px;
    margin: 40px auto;

  }
  
  label, input, textarea {
      display: block;
      margin-bottom: 10px;
    }
  
    input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
      width: 100%;
      padding: 5px;
      background-color: #FFFFFF;
        border: 1px solid #CCCCCC;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
    
h4{
    margin: 45%;
}

.contactContainer{
    height: 184vh;
    margin-top: 10px;
}


textarea{
    height: 100px;
    border-radius: 5px;
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}


.innerDiv{
    width: 100%;
    margin-left: 10%;
    padding:0% 10%;
    /* border: 2px solid red; */
    margin-top: 0%;
}

.contactTable{
    margin-top: 5%
}

h5{
    padding: 0px 0px;
}

.contactTable td{
    height: 76px;
    padding: 5px 5px;
    vertical-align: top;
    
}

.contactTable tr{
    padding: 0px;
    margin: 0px;
}

.contactContainer{
    background-color: #ddf3f7;
}

.tableDiv{
    grid-row: 1;
    grid-column: 1;
}

.gmapDiv{
    grid-row: 1;
    grid-column: 2;
}

.detailsDiv{
    margin-left: 24%;
    margin-top: 50px;
    /* border: 2px solid red; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 210px;
    justify-content: center;
    width: 50%;
    height: 350px;
    /* display: flex; */
}

label{
    display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}