#image{
  width: 100%;
  /* margin-left: 0px; */
  background-size: cover;
  height: 550px;
  opacity: 0.5;
  z-index: -1;
}

/* .container {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    border: 2px solid red;
    position: relative;
  } */

/* .text-overlay {
    position: absolute;
    left: 40%;
    top: 20%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: rgb(180, 115, 29);
} */

.subtitle {
    font-size: 1.5rem;
}

.hero-image-container {
  position: relative;
}

.text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Adjust the background color and opacity as desired */
  display: flex;
  justify-content: center;
  align-items: center;
}


.content-container {
  text-align: center;
  color: #fff; /* Set the text color for the content */
}

/* Styles for the headline */
.headline {
  font-size: 3rem;
  margin-bottom: 10px;
}

/* Styles for the CTA text */
.cta-text {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.company-name {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff; /* Set the text color for the company name */
}
