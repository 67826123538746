body {
    /* background-image: linear-gradient(to bottom, #00d0ff, #b50aee); */
    transition: background-position 1s ease-in-out;
  }
  
  body {
    background-position: 0 0;
  }
  
  body::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: url('https://img.freepik.com/free-vector/elegant-white-background-with-shiny-lines_1017-17580.jpg?w=1060&t=st=1681712776~exp=1681713376~hmac=9108983597afeddeb9c1fb18db57aa9f8f9bb0642eadfc907bf232e16c27d042') center center no-repeat;
    background-size: cover;
    opacity: 0.3;
    transform: scale(1.1);
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }
  
  body.scrolled {
    background-position: 0 -100px;
  }
  
  body.scrolled::before {
    opacity: 1;
    transform: scale(1);
  }
  
  .info {
    margin: 30px 30px 15px 0;
    padding: 30px;
  }
  
  .buttonRow {
    margin-top: 80px;
  }
  
  .item {
    /* background: #fafdff; */
    /* background: url('https://images.pexels.com/photos/7135121/pexels-photo-7135121.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2') center center no-repeat; */
  }
  
  .buttonRow {
    margin-top: 80px;
  }
  
  .divi {
    margin: 10px 30px;
    height: 500px;
  }
  
  .divi img {
    max-width: 100%;
    height: 90%;
  }
  
.info h4{
  margin: 15px 0px;
}