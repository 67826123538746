.product--image {
    width: 90%;
    /* border: 2px solid red; */
    justify-content: center;
    margin: 1rem 1rem;
    height: 9em;
    /* border: 2px solid red; */
    object-fit: contain;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 250px;
    padding: 0px 0px;
    margin: 40px 1rem;
    text-align: center;
    height: 300px;
    font-family: arial;
}
  
.price {
    color: grey;
    font-size: 22px;
}
  
.card button {
    border: none;
    outline: 0;
    padding: 12px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
}
  
.card button:hover {
    opacity: 0.7;
}

#productDiv{
    margin: 70px 60px;
}

.product--name {
    /* Add styles to keep the product name in a single line */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide any overflowing text */
    text-overflow: ellipsis; /* Show ellipsis (...) when text overflows */
    width: 100%; /* Ensure the width of the element */
  }