.navbar{
    display:flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    /* border: 2px solid red; */
    padding: 10px;
    height: 77px;
    z-index: 1;
}

.navbar-logo img{
    width: 85px;
    height: 70px;
}

.navbar-links{
    list-style: none;
    display: flex;
    align-items: center;
    gap: 20px;
}

.navbar-links li a {
  color: var(--color-primary-400);
    padding: 4px 4px ;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.2rem;
}

.navbar-links li a:hover {
    color: #555;
  }
  
  
  
  .navbar-links li a.active {
  color: #555;
  /* color: blue; */
  /* color: var(--color-primary-800); */
}

#navbarSticky{
    position: sticky;
    top: 0;
}


/* Navbar.css */
.dropdown {
    position: relative;
    /* z-index: 3; */
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    font-size: 1rem;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  

