.aboutContainer{
    background-color: #caecf2;
    /* min-height: 500vh; */
    /* min-width: 100%; */
    height: 1200px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: #333333;
    /* border: 2px solid red; */
    grid-gap: 20px;
    /* grid-gap: 1rem; */
    /* margin-top: 2rem; */
}

.image img{
    /* border: 2px solid red; */
    max-width: 100%;
}

.image{
    /* border: 2px solid red; */
    grid-row: 1;
    grid-column: 2;
    height: fit-content;
    max-width: 100%;
    padding: 2px 28px;
    margin: 0px 4px;
}

.script{
    /* border: 2px solid blue; */
    grid-row: 1;
    grid-column: 1;
    margin-top: 0px;
    padding: 2px 28px;
    margin: 2px 4px;
}

#thirdDiv{
    /* border: 2px solid green; */
    grid-row: 2;
    height: 700px;
    grid-column: 2;
    margin-top: 0%;
    padding: 2px 28px;      
}

#fourthDiv{
    /* border: 2px solid green; */
    height: 700px;
    grid-row: 2;
    grid-column: 1;
    /* height: fit-content; */
    width: 100%;
    padding: 2px 28px;
}


.Abouttable td {
    padding: 5px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    /* border: 2px solid red; */
}
  
th {
    background-color: #f2f2f2;
}


#fourthDiv h5{
    padding: 20px 0px;
    margin-top: 5px;
}

@media (min-width: 418px) and (max-width: 1024px) {
    .aboutContainer {
        display: grid;
        height: 2100px;
        grid-template-columns: 1fr;
      }
    
      
      .image img{
        width: 100%;
        max-height: 70%;
        /* margin: 1rem 2rem; */
    }
    .script {
        /* border: 2px solid red; */
        grid-row: 1;
        grid-column: 1;
        max-width: 100%;
    }

    .image{
        grid-row:2;
        grid-column: 1;
        height: fit-content;
        max-width: 100%;
        /* border: 2px solid red; */
    }
    
    #thirdDiv {
        /* border: 2px solid pink; */
        grid-row: 3;
        grid-column: 1;
        height: 400px;
    }
    
    #fourthDiv {
        /* border: 2px solid pink; */
        grid-row: 4;
        grid-column: 1;
    }
}

@media (max-width: 415px) {
    .aboutContainer {
        /* border: 2px solid red; */
        display: grid;
        height: 2900px;
        grid-template-columns: 1fr;
      }
    
      
      .image img{
        width: 100%;
        max-height: 70%;
        /* margin: 1rem 2rem; */
    }
    .script {
        height: 650px;
        /* border: 2px solid red; */
        grid-row: 1;
        grid-column: 1;
        max-width: 100%;
    }

    .image{
        grid-row:2;
        grid-column: 1;
        height: fit-content;
        margin-top: -490px;
        max-width: 100%;
        /* border: 2px solid red; */
    }
    
    #thirdDiv {
        /* border: 2px solid pink; */
        grid-row: 3;
        grid-column: 1;
        margin-top: -490px;
        height: 400px;
    }
    
    #fourthDiv {
        /* border: 2px solid pink; */
        margin-top: -490px;
        grid-row: 4;
        grid-column: 1;
    }
}