.Typecard {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    width: 250px;
    /* margin: 20px 20px; */
    text-decoration: none;
    /* border: 2px solid red; */
    height: 170px;
}

.Typeproduct--image{
    width: 100%;
    height: 96px;
    object-fit: contain;
    /* border: 2px solid rgb(0, 85, 255); */    
}

.Typecard:hover{
    cursor: pointer;
    color: rgba(84, 83, 83, 0.919);
}

.linkStyle {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Use the default color */
}
  